import React, {useCallback, useEffect, useState} from 'react';
import {
  RefreshControl,
  View,
  ScrollView,
  Animated,
  DeviceEventEmitter,
} from 'react-native';
import {BasicObject, SafeAny} from '@types';
import theme from '@style';
import {
  getDigitList,
  getBannerList,
  getKeralaList,
  getNoticeCheck,
  getMatkaList,
  getHomeGames,
  getGoldAreaList,
} from './home.service';
import {envConfig} from '@/utils';
import globalStore from '@services/global.state';
import HomeMenu from './components/home-menu';
import HomeHeader from './components/home-header';
import Download from './components/download';
import HomeBanner from './components/home-banner';
import {
  BannerListItem,
  DigitListItem,
  KeralaListItem,
  MatkaListItem,
  HomeGameFloorItem,
  GoldAreaItem,
} from './home.type';
import HomeGoldArea from './components/home-gold-area';
import {debounce, setDataForSettled} from '@/utils';
import HomeService from './components/home-service';
// import HomeChat from './components/home-chat';
import {useFocusEffect} from '@react-navigation/native';
import {NoMoreData} from '@basicComponents/default-page';
import Spin from '@basicComponents/spin';
import Drawer, {DrawerRef} from '@basicComponents/drawer/drawer';
import Tabs from './components/tab/tab';
import HomeTabListContent from './home-list-tab-content';

const filterGames = ['casino', 'liveCasino', 'scratch'];

const Home = () => {
  const menuRef = React.useRef<DrawerRef>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const setMenuOpen = (bool: boolean, _unreadCount?: number) => {
    if (bool) {
      setUnreadCount(_unreadCount || 0);
      menuRef.current?.open();
    } else {
      menuRef.current?.close();
    }
  };
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const [homeGames, setHomeGames] = useState<HomeGameFloorItem[]>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [bannerList, setbannerList] = React.useState<BannerListItem[]>([]);
  const [keralaList, setKeralaList] = useState<KeralaListItem[]>([]);
  const [digitList, setDigitList] = useState<DigitListItem[]>([]);
  const [matkaList, setMatkaList] = useState<MatkaListItem[]>([]);
  const [goldenList, setGoldenList] = useState<GoldAreaItem[]>([]);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [version, setVersion] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [imgs, setImgs] = useState({} as {[key: string]: string});
  const topHeight = React.useRef(0);

  const getImgs = () => {
    getGoldAreaList('SUSPENSION_APP')
      .then(r => {
        /**
         * {
         * "IM_APP":
         * CUSTOMER_SERVICE
         * }
         */
        setImgs(
          r.reduce((pre, cur) => {
            // 存贮im图标
            if (cur.moduleType === 'IM_APP') {
              globalStore.imIcon = cur.defaultImgUrl;
            }
            (pre as SafeAny)[cur.moduleType] = cur.defaultImgUrl;
            return pre;
          }, {}),
        );
      })
      .catch(e => console.log('e', e));
  };
  const getList = (refresh: boolean = false) => {
    if (!refresh) {
      setPageLoading(true);
    }
    Promise.allSettled([
      getBannerList(2),
      getGoldAreaList(),
      getHomeGames(),
      getKeralaList(),
      getDigitList(),
      getMatkaList(),
    ])
      .then(([banner, golds, games, kerala, digit, matka]) => {
        setDataForSettled(setbannerList, banner);
        setDataForSettled(setGoldenList, golds);
        setDataForSettled(setHomeGames, games);
        setDataForSettled(setKeralaList, kerala);
        setDataForSettled(setDigitList, digit);
        setDataForSettled(setMatkaList, matka);
      })
      .finally(() => {
        if (!refresh) {
          DeviceEventEmitter.emit('startDownload');
        }
        setPageLoading(false);
        setRefreshing(false);
      });
  };
  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };

  useEffect(() => {
    getImgs();
    getList();
    // scrollAnim.addListener(e => {
    //   handleScroll(e);
    // });
    return () => {
      setRefreshing(false);
      scrollAnim.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      doNotice(token);
      globalStore.amountCheckOut.next({});
      if (token) {
        globalStore.refreshNotification.next();
      }
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(handleFocusEffect);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const scrollAnim = React.useRef(new Animated.Value(0)).current;
  const measures = React.useRef<number[]>([]);

  const handleScroll = (y: number) => {
    const totalTop = topHeight.current + 0;
    if (!totalTop) {
      return;
    }
    if (y >= totalTop + getToTopHeight(6)) {
      setActiveTab(6);
    } else if (y >= totalTop + getToTopHeight(5)) {
      setActiveTab(5);
    } else if (y >= totalTop + getToTopHeight(4)) {
      setActiveTab(4);
    } else if (y >= totalTop + getToTopHeight(3)) {
      setActiveTab(3);
    } else if (y >= totalTop + getToTopHeight(2)) {
      setActiveTab(2);
    } else if (y >= totalTop + getToTopHeight(1)) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };

  const handleScrollTo = debounce((y: number) => {
    handleScroll(y);
  }, 50);

  const homeFilterList = React.useMemo(() => {
    if (homeGames && homeGames.length > 0) {
      return homeGames.filter(item => !filterGames.includes(item.uniqueKey));
    }
    return [];
  }, [homeGames]);

  const getToTopHeight = (index: number) => {
    return measures.current
      .slice(0, index)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const scrollToCurrentGame = (index: number) => {
    // const tabHeight = 48;
    const totalTop = topHeight.current;
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: getToTopHeight(index) + totalTop + 2,
      animated: true,
    });
  };

  return (
    <Spin loading={pageLoading} style={[theme.flex.col, theme.fill.fill]}>
      <View
        style={[
          theme.fill.fill,
          theme.flex.col,
          theme.background.lightGrey,
          theme.position.rel,
        ]}>
        <HomeHeader setMenuOpen={setMenuOpen} version={version} />
        {globalStore.isWeb ? <Download /> : null}
        <Animated.ScrollView
          ref={scrollViewRef}
          onScroll={e => {
            handleScrollTo(e.nativeEvent.contentOffset.y);
          }}
          style={[theme.flex.flex1]}
          stickyHeaderIndices={[1]}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                getList(true);
                doNotice(globalStore.token);
                setVersion(v => v + 1);
              }}
            />
          }>
          <View
            onLayout={e => {
              topHeight.current = e.nativeEvent.layout.height;
            }}>
            <HomeBanner bannerList={bannerList} />
            <HomeGoldArea
              list={goldenList}
              noticeMap={noticeMap}
              onNotice={() => doNotice(globalStore.token)}
            />
          </View>
          {homeFilterList.length > 0 && (
            <Tabs
              homeGames={homeFilterList}
              activeTab={activeTab}
              scrollToCurrentGame={scrollToCurrentGame}
            />
          )}
          <HomeTabListContent
            homeGames={homeFilterList}
            digitList={digitList}
            keralaList={keralaList}
            matkaList={matkaList}
            onMeasure={(index: number, anchor: number) => {
              measures.current[index] = anchor;
              measures.current = [...measures.current];
            }}
          />
          <NoMoreData text="" />
        </Animated.ScrollView>
        {/* {!!imgs.IM_APP && <HomeChat img={imgs.IM_APP} />} */}
        {!!imgs.CUSTOMER_SERVICE && <HomeService img={imgs.CUSTOMER_SERVICE} />}
      </View>
      <Drawer ref={menuRef}>
        <HomeMenu setMenuOpen={setMenuOpen} unreadCount={unreadCount} />
      </Drawer>
      <iframe
        src={`${envConfig.IMURL}/#/empty`}
        height={'0%'}
        width={'0%'}
        allow="autoplay; clipboard-read; clipboard-write; camera; microphone"
      />
    </Spin>
  );
};

export default Home;
