import {SafeAny} from '@/types';
import {http, imApiHttp, indusWinHttp, sportsHttp} from '@utils';
import globalStore from './global.state';
import {packageId} from '@/config';
import DeviceInfo from 'react-native-device-info';
import {getAdjustAttribution} from '@/utils/adjust';

export interface MessageCountInfo {
  messageTotalCount: number;
  sysMessageCount: number;
  sysUserMessageCount: number;
}

// 这里用来放一些全局要用到的请求

/** 获取刮刮乐令牌 */
export const setScratchAuth = (
  callback?: ({authorize, url}: {authorize: string; url: string}) => void,
  finallyBack?: () => void,
  catchBack?: (e: SafeAny) => void,
) => {
  indusWinHttp
    .post<null, {authorize: string; url: string}>(
      'iGaming/api/player/authorize',
    )
    .then(auth => {
      if (auth) {
        globalStore.setItem('scratchToken', auth.authorize);
        globalStore.setItem('scratchUrl', auth.url);
      }
      callback?.(auth);
    })
    .catch(e => {
      catchBack?.(e);
    })
    .finally(() => {
      finallyBack?.();
    });
};

/** 获取用户余额 */
export const getBalance = (gameType?: string) => {
  return http.post<
    null,
    {
      mainBalance: number;
      totalBalance: number;
      [key: string]: SafeAny;
    }
  >(`app/user/getBalanceV2?gameType=${gameType || ''}`);
};

export interface IUserInfo {
  canWithdrawAmount: number; // 可兑现金额
  createTime: number;
  level: number;
  rechargeAmount: number; // 充值金额
  userBalance: number; // 用户总额
  userPhone: string;
  userId: number;
  updateTime: number;
  lastLoginTime: number;
  // 0 不是代理 1 是代理
  isAgent: number;
  // 代理等级
  agentLevel?: number;
  // 缺少用户名
  [k: string]: SafeAny;
}

export interface IVipItem {
  amount: number;
  diff: number;
  level: number;
  name: string;
  // 0 已领取 1 未领取
  rewardReceivingStatus: number;
  // 0 未抵达 1 已抵达
  statusReached: number;
  totalPay: number;
  [k: string]: SafeAny;
}

export type IVipItemList = IVipItem[];

export interface IVipConfigItem {
  amount: number;
  level: number;
  spin: number;
  recharge: number;
  [k: string]: SafeAny;
}

export interface MessageCountInfo {
  messageTotalCount: number;
  sysMessageCount: number;
  sysUserMessageCount: number;
}

export interface ReadMessageParams {
  messageId: number;
  messageType: number;
}

export interface SlotegratorStartParams {
  id: string;
  forFun: string;
}

/** 获取用户信息 */
export function postUserInfo() {
  return http.post<null, IUserInfo>('app/user/info');
}

export const noticeCheckOut = (gameType?: string) => {
  globalStore.token &&
    indusWinHttp
      .get('iGaming/api/noticeCheckOut')
      .then(() => globalStore.updateAmount.next({gameType}));
};

/** 获取用户消息未读条数 */
export function getMessageNotReadCount() {
  return http.get<null, MessageCountInfo>('app/userMessage/unReadCount');
}

/** 读取消息
 * @param messageType 1 系统消息 2 用户消息
 */
export function postReadMessage(messageId: number, messageType: 1 | 2) {
  return http.post<ReadMessageParams, null>('app/userMessageStatus/readMsg', {
    messageId,
    messageType,
  });
}

export function getSlotegratorGameStart(gameId: string) {
  return indusWinHttp.get<SlotegratorStartParams, string>(
    '/iGaming/casino/getGamesStart',
    {
      params: {
        id: gameId,
        forFun: 'false',
      },
    },
  );
}

export type IVipConfig = IVipConfigItem[];

export function postVipInfo() {
  return http.post<null, IVipItemList>('app/membershipCard/getMemberCardInfo');
}

export function postVipConfig() {
  return http.post<null, IVipConfig>('app/membershipCard/getConfig');
}

// export const getSprotUrl = () => {
//   return sportsHttp.get<null, string>('sap/start');
// };
// sports 游戏分类处理 saba_sport
export const getSprotUrl = (gameType?: string) => {
  return sportsHttp.get<string, string>(
    `sportGame/getGameUrl?gameType=${gameType || 'sap_sport'}`,
  );
};

export type TGameCode =
  | 'dice'
  | 'color'
  | '3Digit'
  | 'scratch'
  | 'matka'
  | 'kerala'
  | 'stateLottery'
  | 'quick3d'
  | 'quickRace'
  | 'casino'
  | 'live'
  | 'sport'
  | 'quickStateLottery';

export const getShareAward = (gameCode?: TGameCode) => {
  return http.get<TGameCode, number>(
    'app/share/getShareAward?gameCode=' + gameCode,
  );
};

// 数据上报
export enum TReportType {
  CLICK = '1',
  BETS = '2',
  REGISTER = '3',
}
export enum TSourceType {
  IM = '1',
  CHANNEL = '2',
}
export interface IReportData {
  reportType: TReportType; // 1点击 2下单 3注册
  // packageId: string;
  gameName?: string;
  gameType?: TGameCode;
  userId?: string;
  inviteCode?: string;
  gameId?: string;
  issueNo?: string;
  // deviceCode?: string;
  sourceType?: TSourceType; // 1 IM 2 渠道
  channelName?: string;
  shareUserId?: string; //分享用户id
  extData?: string; //扩展数据
}

export async function postReport(data: IReportData) {
  const deviceCode = globalStore.isWeb
    ? getAdjustAttribution()?.adid
    : await DeviceInfo.getAndroidId();
  return http.post<IReportData, null>('app/dataReport/add', {
    packageId,
    deviceCode,
    ...data,
  });
}

interface RechargeCustomerItem {
  imCustomer: string;
  imUserId: string;
  imUserStatus: string;
  userAvatar: string;
  userId: number;
  userName: string;
}

export async function getRechargeCustomers() {
  return imApiHttp.get<null, RechargeCustomerItem[]>('im/zgIm/customers');
}
